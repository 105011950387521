export enum AvenAdvisorTab {
    Overview = 'Overview',
    Accounts = 'Accounts',
    Assets = 'Assets',
    Shopping = 'Shopping',
    Rewards = 'Rewards',
}

export const avenAdvisorTabToClickEventName: Record<AvenAdvisorTab, string> = {
    [AvenAdvisorTab.Overview]: 'click_button_advisor_overview_tab',
    [AvenAdvisorTab.Accounts]: 'click_button_advisor_accounts_tab',
    [AvenAdvisorTab.Assets]: 'click_button_advisor_assets_tab',
    [AvenAdvisorTab.Rewards]: 'click_button_advisor_rewards_tab',
    [AvenAdvisorTab.Shopping]: 'click_button_advisor_shopping_tab',
}
